
import { GENRE_CHOICES, TARGET_AUDIENCE_CHOICES, PREFECTURE_CHOICES, IS_INSTRUCTOR_CHOICES } from "../constants/dropdownChoices";
import { INSTRUCTOR_LESSON_STATUS_CHOICES } from "../constants/dropdownChoices";

export const displayGenre = (value: string, language: 'en' | 'jp'): string => {
  const genre = GENRE_CHOICES.find(genre => genre.en === value);
  return genre ? genre[language] : value;
};

export const displayTargetAudience = (value: string, language: 'en' | 'jp'): string => {
  const target_audience = TARGET_AUDIENCE_CHOICES.find(t => t.en === value);
  return target_audience ? target_audience[language] : value;
};

export const displayPrefecture = (value: string, language: 'en' | 'jp'): string => {
  const prefecture = PREFECTURE_CHOICES.find(t => t.en === value);
  return prefecture ? prefecture[language] : value;
};

export const displayIsInstructor = (value: boolean, language: 'en' | 'jp'): string => {
  const isOrganizer = IS_INSTRUCTOR_CHOICES.find(t => t.value === value);
  return isOrganizer ? isOrganizer[language] : '';
}

// active, in-progress, inactive, draft
export const displayInstructorLessonStatus = (value: string, language: 'en' | 'jp'): string => {
  const status = INSTRUCTOR_LESSON_STATUS_CHOICES.find(t => t.en === value);
  return status ? status[language] : '';
}

export const displayDeadline = (deadline:string, language:'en' | 'jp'): string => {
  const num = deadline.slice(0,-1)
  const freq = deadline.slice(-1)
  if (language=='en') {
    const freqWordEn = freq=='M' ? 'minute(s)' : freq=='H' ? 'hour(s)' : freq=='D' ? 'day(s)' : ''
    return `${num} ${freqWordEn}`
  } else {
    const freqWordJp = freq=='M' ? '分' : freq=='H' ? '時間' : freq=='D' ? '日前' : ''
    return `${num}${freqWordJp}`
  }
}

